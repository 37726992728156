import React, { useState } from "react";
import CustomTabs from "components/CustomTabs/CustomTabs";
import { useRecoilValue, useRecoilState } from "recoil";
import {
  poListAdminViewAtom,
  poListAdminViewStartDateMonthAtom,
  poListAdminViewStartDateYearAtom,
  poListAdminViewNeedCheckAtom,
  getPOListAdminView,
  getPOListAdminViewNeedCheck
} from "state/purchaseOrders";
import Loading from "components/Loading/Loading";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "components/CustomButtons/Button";
import AdminDownloadModal from "components/AdminDownloadModal/AdminDownloadModal";

import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import ListPOs from "../ListPOs";

const useStyles = makeStyles(styles);

const AdminViewComponent = (props) => {
  const tab = +(props.location.search?.split("=")[1] || 0);
  const redirect = props.location.pathname.split('/purchase-orders/')[1] + '_' + tab

  const [poList, setPoList] = useRecoilState(poListAdminViewAtom);
  const [needCheckList, setNeedCheckList] = useRecoilState(poListAdminViewNeedCheckAtom)
  //const pending = useRecoilValue(poListAdminViewPendingReviewAtom)

  const [month, setMonth] = useRecoilState(poListAdminViewStartDateMonthAtom);
  const [year, setYear] = useRecoilState(poListAdminViewStartDateYearAtom);

  const classes = useStyles();

  const updatePOList = async () => {
    let newList = await getPOListAdminView(month, year)
    setPoList(newList)
    let needCheck = await getPOListAdminViewNeedCheck()
    setNeedCheckList(needCheck)
  }

  const monthChangeHandler = async (e) => {
    setMonth(e.target.value);
    sessionStorage.setItem("plastsdm", e.target.value);
    let newList = await getPOListAdminView(e.target.value, year)
    setPoList(newList)
    let needCheck = await getPOListAdminViewNeedCheck()
    setNeedCheckList(needCheck)
  };

  const yearChangeHandler = async (e) => {
    setYear(e.target.value);
    sessionStorage.setItem("plavsdya", e.target.value);
    let newList = await getPOListAdminView(month, e.target.value)
    setPoList(newList)
    let needCheck = await getPOListAdminViewNeedCheck()
    setNeedCheckList(needCheck)
  };

  const cols = [
    {
      Header: "ID",
      accessor: "id",
      disableFilters: false,
      disableSortBy: true,
    },
    {
      Header: "Vendor",
      accessor: "vendor",
      disableFilters: false,
      disableSortBy: true,
    },
    {
      Header: "Created",
      accessor: "submittedDate",
      disableFilters: false,
      disableSortBy: true,
    },
    {
      Header: "Creator",
      accessor: "submittedByName",
      disableFilters: false,
      disableSortBy: true,
    },
    {
      Header: "Reviewer",
      accessor: "reviewerName",
      disableFilters: false,
      disableSortBy: true,
    },
    {
      Header: "Reviewer Edited",
      accessor: "ReviewerEditedLineItems",
      disableFilters: false,
      disableSortBy: true,
    },
    {
      Header: "Approver(s)",
      accessor: "submittedToName",
      disableFilters: false,
      disableSortBy: true,
    },
    {
      Header: "RFP",
      accessor: "purchaseReason",
      disableFilters: false,
      disableSortBy: true,
    },
    {
      Header: "Total",
      accessor: "total",
      disableFilters: false,
      disableSortBy: true,
    },
    {
      Header: "Actions",
      accessor: "actions",
      disableFilters: true,
      disableSortBy: true,
    },
  ]

  const checkCols = [
    {
      Header: "ID",
      accessor: "id",
      disableFilters: false,
      disableSortBy: true,
    },
    {
      Header: "Vendor",
      accessor: "vendor",
      disableFilters: false,
      disableSortBy: true,
    },
    {
      Header: "Created",
      accessor: "submittedDate",
      disableFilters: false,
      disableSortBy: true,
    },
    {
      Header: "Creator",
      accessor: "submittedByName",
      disableFilters: false,
      disableSortBy: true,
    },
    {
      Header: "Reviewer",
      accessor: "reviewerName",
      disableFilters: false,
      disableSortBy: true,
    },
    {
      Header: "Reviewer Edited",
      accessor: "ReviewerEditedLineItems",
      disableFilters: false,
      disableSortBy: true,
    },
    {
      Header: "Approver(s)",
      accessor: "submittedToName",
      disableFilters: false,
      disableSortBy: true,
    },
    {
      Header: "Total",
      accessor: "total",
      disableFilters: false,
      disableSortBy: true,
    },
    {
      Header: "RFP",
      accessor: "purchaseReason",
      disableFilters: false,
      disableSortBy: true,
    },
    {
      Header: "Check By",
      accessor: "payCheckBy",
      disableFilters: false,
      disableSortBy: true,
    },
    {
      Header: "Actions",
      accessor: "actions",
      disableFilters: true,
      disableSortBy: true,
    },
  ]

  const tabs = [
    {
      tabName: "Needs Review",
      tabContent: (
        <ListPOs
          cols={cols}
          redirect={redirect}
          buttons={{
            adminView: true,
            adminEdit: true,
            manageFiles: true,
          }}
          updatePOList={updatePOList}
          poList={poList.filter(
            (item) =>
              item.status === "review"
          )}
        />
      ),
    },
    {
      tabName: "Pending",
      tabContent: (
        <ListPOs
          cols={cols}
          redirect={redirect}
          buttons={{
            adminView: true,
            adminEdit: true,
            manageFiles: true,
          }}
          updatePOList={updatePOList}
          poList={poList.filter(
            (item) =>
              item.status === "pending" ||
              item.status.indexOf("awaiting") !== -1
          )}
        />
      ),
    },
    {
      tabName: "Credit Cards",
      tabContent: (
        <ListPOs
          cols={cols}
          redirect={redirect}
          buttons={{
            adminView: true,
            adminEdit: true,
            manageFiles: true,
            archive: true,
            download: true
          }}
          updatePOList={updatePOList}
          poList={poList.filter(
            (item) =>
              item.paymentBy !== "check" &&
              (item.status === "approved")
          )}
        />
      ),
    },
    {
      tabName: "Check Requests",
      tabContent: (
        <ListPOs
          cols={checkCols}
          redirect={redirect}
          buttons={{
            adminView: true,
            adminEdit: true,
            manageFiles: true,
            archive: true,
            download: true,
            checkPending: true
          }}
          updatePOList={updatePOList}
          poList={needCheckList.filter(item => item.checkProcessed !== 'pending')}
        />
      ),
    },
    {
      tabName: "Check Pending",
      tabContent: (
        <ListPOs
          cols={checkCols}
          redirect={redirect}
          buttons={{
            adminView: true,
            adminEdit: true,
            manageFiles: true,
            archive: true,
            download: true,
            checkProcessed: true
          }}
          updatePOList={updatePOList}
          poList={needCheckList.filter(item => item.checkProcessed === 'pending')}
        />
      ),
    },
    {
      tabName: "Checks Cut",
      tabContent: (
        <ListPOs
          cols={checkCols}
          redirect={redirect}
          buttons={{
            adminView: true,
            adminEdit: true,
            manageFiles: true,
            archive: true,
            download: true
          }}
          updatePOList={updatePOList}
          poList={poList.filter(
            (item) =>
              item.paymentBy === "check" && item.checkProcessed === 'yes' &&
              item.status === "approved"
          )}
        />
      ),
    },
    {
      tabName: "Rejected",
      tabContent: (
        <ListPOs
          cols={cols}
          redirect={redirect}
          buttons={{
            adminView: true,
          }}
          updatePOList={updatePOList}
          poList={poList.filter((item) => item.status === "rejected")}
        />
      ),
    },
  ];

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const years = ["2021", "2022", "2023", "2024", "2025", "2026", "2027", "2028"];
  const [showDownloadModal, setShowDownloadModal] = useState(false);

  const bulkDownloadClickHandler = () => {
    setShowDownloadModal(true);
  };

  const downloadModalCloseHandler = () => {
    setShowDownloadModal(false);
  };

  return (
    <React.Fragment>
      {showDownloadModal && (
        <AdminDownloadModal closeHandler={downloadModalCloseHandler} />
      )}
      <GridContainer style={{ alignItems: "center" }}>
        <GridItem xs={6} lg={2}>
          <FormControl fullWidth className={classes.selectFormControl}>
            <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
              Select Month
            </InputLabel>
            <Select
              MenuProps={{
                className: classes.selectMenu,
              }}
              classes={{
                select: classes.select,
              }}
              value={month}
              onChange={monthChangeHandler}
              inputProps={{
                name: "monthSelect",
                id: "month-select",
              }}
            >
              {months.map((item, index) => {
                return (
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected,
                    }}
                    value={index}
                    key={item}
                  >
                    {item}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </GridItem>
        <GridItem xs={6} lg={2}>
          <FormControl fullWidth className={classes.selectFormControl}>
            <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
              Select Year
            </InputLabel>
            <Select
              MenuProps={{
                className: classes.selectMenu,
              }}
              classes={{
                select: classes.select,
              }}
              value={year}
              onChange={yearChangeHandler}
              inputProps={{
                name: "yearSelect",
                id: "year-select",
              }}
            >
              {years.map((item, index) => {
                return (
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected,
                    }}
                    value={item}
                    key={item}
                  >
                    {item}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </GridItem>
        <GridItem lg={2}>
          <Button color="warning" onClick={bulkDownloadClickHandler}>
            Download
          </Button>
        </GridItem>
      </GridContainer>
      <CustomTabs headerColor="info" tabs={tabs} value={tab} />
    </React.Fragment>
  );
};

const AdminViewLoading = () => {
  const tabs = [
    {
      tabName: "Needs Review",
      tabContent: <Loading color="blue" />,
    },
    {
      tabName: "Pending",
      tabContent: <Loading color="blue" />,
    },
    {
      tabName: "Credit Cards",
      tabContent: <Loading color="blue" />,
    },
    {
      tabName: "Check Requests",
      tabContent: <Loading color="blue" />,
    },
    {
      tabName: "Check Pending",
      tabContent: <Loading color="blue" />,
    },
    {
      tabName: "Cut Checks",
      tabContent: <Loading color="blue" />,
    },
    {
      tabName: "Rejected",
      tabContent: <Loading color="blue" />,
    },
  ];

  return (
    <React.Fragment>
      <GridContainer>
        <GridItem xs={3}>
          <p>Select Month</p>
          <p>
            <select>
              <option>Select Month</option>
            </select>
          </p>
        </GridItem>
        <GridItem xs={3}>
          <p>Select Year</p>
          <p>
            <select>
              <option>Select Year</option>
            </select>
          </p>
        </GridItem>
      </GridContainer>
      <CustomTabs headerColor="info" tabs={tabs} />
    </React.Fragment>
  );
};

const AdminView = (props) => {
  return (
    <React.Suspense fallback={<AdminViewLoading />}>
      <AdminViewComponent {...props} />
    </React.Suspense>
  );
};

export default AdminView;
